<template>
  <v-container>
    <p
      class="section_titles"
      v-text="section_title"
    />
    <v-card
      flat
      class="about_wrapper">
      <v-layout
        row
        justify-center
        wrap>
        <v-flex
          xs12
          sm12>
          <v-layout 
            row
            justify-center>
            <contacts-table />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    section_title: 'Contactos',
    load_contacts: false,
    contacts: [],
    num_pages: 0,
    current_page: 0,
  }),
  components: {
    ContactsTable: () => import('@/components/sections/Admin/Contact/Table')
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style scoped>
.about_wrapper {
  background-color: transparent;
  width: 100%
}
</style>